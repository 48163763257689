import React from "react";
import "./ReportMachineHour.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";

const ReportMachine = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let array = location?.state?.filterDateHour;
  let arr = [].concat(array).reverse();
  const reportformat = "Nestle\t" + arr[arr?.length - 1]?.date;
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="reportmain">
        <div className="headleft">
          <BsArrowLeftShort
            className="icon cursor"
            onClick={() => navigate(-1)}
          />
          <div className="heading">Event Report</div>
        </div>
        <div className="report" id="reportid">
          <div className="date">{arr[arr?.length - 1]?.date}</div>
          <div className="reportsMainContainer">
            {array?.map((data, index) => {
              return (
                <>
                  <table className="tablemain" id="tablee" key={index}>
                    <thead className="tablehead">
                      <tr>
                        {index === 0 && data[data.length - 1]?.updatedAt && (
                          <th className="tableh" rowSpan="2">
                            Time | Date
                          </th>
                        )}
                        <th className="tableh" colSpan="5">
                          {data?.title}
                        </th>
                      </tr>
                      <tr className="tablerow">
                        {data?.[data?.length - 1]?.temperature && (
                          <th className="tableh">Mode</th>
                        )}
                        {data?.[data?.length - 1]?.temperature && (
                          <th className="tableh">Temp. {" °C"}</th>
                        )}
                        {data?.[data?.length - 1]?.temperature && (
                          <th className="tableh">Chiller Status</th>
                        )}
                        {data?.[data?.length - 1]?.chillerOnTime && (
                          <th className="tableh">Chiller On Time{" Min"}</th>
                        )}
                        {data?.[data?.length - 1]?.totalizer && (
                          <th className="tableh">Total Time{" Min"}</th>
                        )}
                        {data[data.length - 1]?.upperAlarmLimit && (
                          <th className="tableh">Upper Alarm Limit</th>
                        )}
                        {data[data.length - 1]?.lowerAlarmLimit && (
                          <th className="tableh">Lower Alarm Limit</th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="tablebody">
                      {data?.map((ddd, i, array) => {
                        let previousTotal = i > 0 ? array[i - 1]?.totalizer : 0;
                        let difference = ddd.totalizer - previousTotal;
                        return (
                          <tr className="tablerow" key={i}>
                            <>
                              {index === 0 && (
                                <td className="tabled">
                                  {moment(ddd?.updatedAt).format(
                                    "DD-MM-YYYY | HH:mm"
                                  )}
                                </td>
                              )}
                              {ddd?.temperature && (
                                <td className="tabled">{ddd?.mode}</td>
                              )}
                              {ddd?.temperature && (
                                <td className="tabled">{ddd?.temperature}</td>
                              )}
                              {ddd?.chillerStatus && (
                                <td className="tabled">{ddd?.chillerStatus}</td>
                              )}
                              {ddd?.chillerOnTime && (
                                <td className="tabled">{difference}</td>
                              )}
                              {data?.[data?.length - 1]?.totalizer && (
                                <td className="tabled">
                                  {parseInt(ddd?.totalizer).toLocaleString()}{" "}
                                </td>
                              )}
                              {data[data.length - 1]?.upperAlarmLimit && (
                                <td className="tabled">
                                  {parseInt(
                                    ddd?.upperAlarmLimit
                                  ).toLocaleString()}{" "}
                                </td>
                              )}
                              {data[data.length - 1]?.lowerAlarmLimit && (
                                <td className="tabled">
                                  {parseInt(
                                    ddd?.lowerAlarmLimit
                                  ).toLocaleString()}{" "}
                                </td>
                              )}
                            </>
                          </tr>
                        );
                      })}

                      <tr className="tablerow">
                        {index === 0 && (
                          <th className="tableh tablehead" colSpan={4}>
                            Avg | Total
                          </th>
                        )}
                        {data?.[data?.length - 1]?.totalizer && (
                          <td className="tableh tablehead">
                            {parseInt(
                              (data[data?.length - 1]?.totalizer -
                                data[0]?.totalizer) /
                                data?.length
                            ).toLocaleString()}
                          </td>
                        )}
                        {data?.[data?.length - 1]?.totalizer && (
                          <td className="tableh tablehead">
                            {parseInt(
                              data[data?.length - 1]?.totalizer -
                                data[0]?.totalizer
                            ).toLocaleString()}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </>
              );
            })}
          </div>
        </div>

        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
        {array?.map((data, i) => {
          return (
            <div className="areaChartSteamFlow" key={i}>
              <AreaChart
                labels={data?.map((time) =>
                  moment(time?.updatedAt).format("HH:mm")
                )}
                flow={data?.map((data) => parseInt(data?.temperature))}
                flowlabel={data[0]?.title}
                borderColor={data?.map((data2) =>
                  data2?.temperature > data2?.upperAlarmLimit
                    ? "rgb(200,0,0)"
                    : data2?.temperature < data2?.lowerAlarmLimit
                    ? "rgb(0,0,200)"
                    : "rgb(0,176,80)"
                )}
                backGroundColor={"rgba(0,176,80,0.7)"}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
export default ReportMachine;
