import axios from "../AxiosInstance";
import AuthTokenGen from "../Utils/AuthTokenGen";

export const addEmail = async (formData) => {
  let resolved = {
    error: null,
    data: null,
  };
  try {
    let res = await axios({
      url: "/email/add",
      method: "POST",
      data: formData,
      headers: AuthTokenGen(),
    });
    resolved.data = res.data;
  } catch (err) {
    if (err && err.response && err?.response?.data?.message) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "Your Internet is Not Connected";
    }
  }
  return resolved;
};

export const GetEmail = async () => {
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const response = await axios({
      url: `/email/all`,
      method: "GET",
      headers: AuthTokenGen(),
    });
    resolved.data = response.data;
  } catch (err) {
    if (err && err.response && err?.response?.data?.message) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "Your Internet is not Connected";
    }
  }
  return resolved;
};


