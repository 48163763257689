import React, { useEffect, useState } from "react";

// API
import {
  GetDeviceData,
  DeleteDeviceAPI,
  GetDeviceDailyReportData,
  GetDevices,
} from "../../Service/DeviceAPI";
import { useLocation, useNavigate } from "react-router-dom";

// Helper
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiInformationLine } from "react-icons/ri";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

// Styling
import "./Device.scss";

// Other Components
import { useSelector } from "react-redux";
import { Button, Input } from "antd";
import ROLES from "../../Utils/Roles";
import ConfirmationModel from "../Common/ConfirmationModel/ConfirmationModel";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";
import { DateRangePicker } from "rsuite";
import { GetEmail, addEmail } from "../../Service/Email";

// ------------------------------------------Start Function------------------------

export default function Device() {
  // Searching the data from start to end date------------
  const [value, setValue] = useState([]);

  let new1 = moment(value[0])?.format("DD-MM-YYYY");
  let new2 = moment(value[1])?.format("DD-MM-YYYY");

  let sdate = moment(value[0])?.format("YYYY-MM-DD");
  let edate = moment(value[1])?.format("YYYY-MM-DD");

  let sendDate = {
    startDate: new1,
    endDate: new2,
  };
  let dateback = {
    startDate: sdate,
    endDate: edate,
  };
  const settingDate = (event) => {
    if (event == null) {
      setValue([]);
    } else {
      setValue(event);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [emailData, setEmailData] = useState({});
  const [refreshPage, setRefreshPage] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    deviceId: null,
    loading: false,
  });

  const machine = location?.state?.machine;
  const buildingId = location?.state?.buildingId;
  const departmentId = location?.state?.departmentId;

  let machineId = machine?._id;

  const gettingDeviceData = async () => {
    let payload = {
      machineId: machineId,
    };
    let res = await GetDeviceData(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setDeviceData(res?.data?.result);
    }
  };
  const gettingDevices = async () => {
    let payload = {
      machineId: machineId,
    };
    let res = await GetDevices(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setDevices(res?.data?.result);
    }
  };
  const gettingEmail = async () => {
    let res = await GetEmail();
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      console.log("ojsdafkhaklshf", res);
      setEmailData(res?.data?.result);
    }
  };

  useEffect(() => {
    gettingDeviceData();
    gettingDevices();
    gettingEmail();
  }, [refreshPage]);

  const handleEditDevice = (device) => {
    navigate("/device/add", {
      state: {
        device: device,
        machineId: machineId,
        buildingId: buildingId,
        departmentId: departmentId,
      },
    });
  };

  // Getting User Data
  const userData = useSelector((state) => state?.userData);

  const handleAddDevice = () => {
    navigate("/device/add", {
      state: {
        machineId: machineId,
        buildingId: buildingId,
        departmentId: departmentId,
      },
    });
  };

  const handleDeleteDeviceConfirmation = (device) => {
    setDeleteConfirmation({
      open: true,
      deviceId: device?._id,
      loading: false,
    });
  };

  //   Delete Device Handle
  const handleDeleteDevice = async (device) => {
    setDeleteConfirmation({
      ...deleteConfirmation,
      loading: true,
    });
    const res = await DeleteDeviceAPI(deleteConfirmation?.deviceId);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      toast.success(res?.data?.message);
      setRefreshPage(!refreshPage);
    }
    setDeleteConfirmation({
      open: false,
      deviceId: null,
      loading: false,
    });
  };
  const handleNotDeleteDevice = () => {
    setDeleteConfirmation({
      open: false,
      deviceId: null,
      loading: false,
    });
  };
  const enterFormData = (event) => {
    let { name, value } = event.target;
    setEmailData({
      ...emailData,
      [name]: value,
    });
  };

  const AddEmail = async () => {
    const payload = {
      email: emailData?.email,
    };
    const res = await addEmail(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      toast.success(res?.data?.message);
    }
  };

  // the function is use to find the date between start date and last date
  const findByDate = async () => {
    // let startDate = dateback?.startDate;
    // let endDate = dateback?.endDate;
    // let sDate = new Date(startDate).getTime();
    // let eDate = new Date(endDate).getTime();
    // let epStartDate = new Date(sDate - 18000000).getTime();
    // let epEndDate = new Date(eDate + 69000000).getTime();
    // let filterDate = deviceData?.map((meter) => {
    //   return {
    //     ...meter,
    //     meterReadings: meter?.DayReadings.filter((val) => {
    //       let currenDate = new Date(val?.datetime).getTime();
    //       if (currenDate >= epStartDate && currenDate <= epEndDate) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }),
    //   };
    // });
    let payload = {
      machineId: machineId,
      startDate: dateback?.startDate,
      endDate: dateback?.endDate,
    };
    let res = await GetDeviceDailyReportData(payload);
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      // setDeviceData(res?.data?.result);
      navigate("/dev/report", {
        state: { date: sendDate, filterDate: res?.data?.result },
        // state: { date: sendDate, filterDate: filterDate },
      });
    }
  };

  return (
    <>
      <div className="MainDeviceWithFooter">
        <div className="deviceMainContainer">
          <div className="deviceMain">
            <div className="deviceHeader">
              <div className="headleft">
                <BsArrowLeftShort
                  className="icon cursor"
                  onClick={() => navigate(-1)}
                />
                <div className="heading">{machine?.title}</div>
              </div>
              <div className="emailAdd">
                <Input
                  size="large"
                  className="deviceInput"
                  type="text"
                  placeholder="Add Email"
                  name="email"
                  onChange={enterFormData}
                  value={emailData?.email}
                />
                <Button
                  className="btnreport EditBtn"
                  type="submit"
                  onClick={AddEmail}
                >
                  {emailData?.email ? "Update Email" : "Add Email"}
                </Button>
              </div>
              <div className="datepiker">
                <DateRangePicker
                  showOneCalendar
                  className="rangepiker"
                  onChange={(event) => settingDate(event)}
                  value={value}
                  placeholder="Start Date ~ End Date"
                  renderValue={(value) => {
                    return (
                      moment(value[0])?.format("DD-MM-YYYY") +
                      " ~ " +
                      moment(value[1])?.format("DD-MM-YYYY")
                    );
                  }}
                />
                <Button
                  className="btnreport EditBtn"
                  type="submit"
                  onClick={findByDate}
                >
                  {" "}
                  Report
                </Button>
              </div>
              {[ROLES.Admin, ROLES.SuperAdmin].includes(userData?.role) &&
                deviceData?.length < 1 && (
                  <Button className="EditBtn" onClick={handleAddDevice}>
                    Add Device
                  </Button>
                )}
            </div>

            {deviceData && (
              <>
                <div className="tableMain">
                  <table>
                    <thead>
                      <tr>
                        <th>Particulars</th>
                        {deviceData?.map((data, i) => {
                          return (
                            <th key={i}>
                              <div className="titleMain">
                                <div className="title">
                                  {devices[0]?.title} <br />
                                  {devices[0]?.Device_ID}
                                </div>

                                {[ROLES.Admin, ROLES.SuperAdmin].includes(
                                  userData?.role
                                ) && (
                                  <span className="editDelete">
                                    <Button
                                      className="DelBtn"
                                      onClick={() =>
                                        handleDeleteDeviceConfirmation(
                                          devices[0]
                                        )
                                      }
                                    >
                                      <AiFillDelete size={24} />
                                    </Button>
                                  </span>
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Mode</th>
                        {deviceData?.map((data, i) => {
                          return (
                            <td key={i}>{data[data.length-1]?.mode && data[data.length-1]?.mode}</td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Temperature</th>
                        {deviceData?.map((data, i) => {
                          return (
                            <td key={i}>
                              {data[data.length-1]?.temperature && data[data.length-1]?.temperature}
                              {" °C"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Chiller Status</th>
                        {deviceData?.map((data, i) => {
                          return (
                            <td key={i}>
                              {data[data.length-1]?.chillerStatus && data[data.length-1]?.chillerStatus}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Last Chiller On Time</th>
                        {deviceData?.map((data, i) => {
                          return (
                            <td key={i}>
                              {data[data.length-1]?.chillerOnTime &&
                                parseInt(
                                  data[data.length-1]?.chillerOnTime
                                ).toLocaleString()}{" "}
                              {" Min"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Total Chiller On Time</th>
                        {deviceData?.map((data, i) => {
                          return (
                            <td key={i}>
                              {data[data.length-1]?.totalizer &&
                                parseInt(
                                  data[data.length-1]?.totalizer
                                ).toLocaleString()}{" "}
                              {" Min"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Update</th>
                        {deviceData?.map((data, i) => {
                          return (
                            <td key={i}>
                              {moment(data[data.length-1]?.updatedAt).format(
                                "DD-MM-YYYY |HH:mm"
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {deviceData?.map((data, i) => {
              // data = data.reverse();
              return (
                <div className="areaChartSteamFlow" key={i}>
                  <AreaChart
                    labels={data?.map((time) =>
                      moment(time?.updatedAt).format("DD |HH:mm")
                    )}
                    flow={data?.map((data1) => parseInt(data1?.temperature))}
                    flowlabel={data[data.length-1]?.title}
                    borderColor={data?.map((data2) =>
                      data2?.temperature > data2?.upperAlarmLimit
                        ? "rgb(200,0,0)"
                        : data2?.temperature < data2?.lowerAlarmLimit
                        ? "rgb(0,0,200)"
                        : "rgb(0,176,80)"
                    )}
                    backGroundColor={"rgba(0,176,80,0.5)"}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <ConfirmationModel
        open={deleteConfirmation.open}
        onOk={handleDeleteDevice}
        onCancel={handleNotDeleteDevice}
        confirmLoading={deleteConfirmation.loading}
        test={deleteConfirmation?.deviceId}
      >
        <div className="deleteModel">
          <div className="titleBox">
            <RiInformationLine className="icon" />{" "}
            <div className="title">
              {" "}
              Are you sure you want to delete this Device?{" "}
            </div>
          </div>
        </div>
      </ConfirmationModel>
    </>
  );
}
